import React from 'react';

function Memes() {
    return (
        <div className='memes-box'>
            <h1 className='memes-title'>Memes</h1>
            <div className='memes'>
                <img alt='meme' className='memes-img' src='./images/meme1.jpg'></img>
                <img alt='meme' className='memes-img' src='./images/meme2.jpg'></img>
                <img alt='meme' className='memes-img' src='./images/meme3.jpg'></img>
                <img alt='meme' className='memes-img' src='./images/meme4.jpg'></img>
                {/* <img alt='meme' className='memes-img' src='/'></img>
                <img alt='meme' className='memes-img' src='/'></img>
                <img alt='meme' className='memes-img' src='/'></img>
                <img alt='meme' className='memes-img' src='/'></img>
                <img alt='meme' className='memes-img' src='/'></img> */}
            </div>
        </div>
    );
}

export default Memes;
