import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import './App.css';
import Tokenomics from './tokenomics';
import Roadmap from './roadmap';
import Memes from './memes';

function App() {
    return (
        <Router>
            <MainApp />
        </Router>
    );
}

function MainApp() {
    const location = useLocation();
    const isHome = location.pathname === '/'; 

    return (
        <div className={`App ${isHome ? 'home-background' : 'no-background'}`}>
            <header>
                <nav className="navbar">
                    <div className='nav-outline'>
                    <Link to="/" className="logo">$Foxy</Link>
                    <ul className="nav-links">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/tokenomics">Tokenomics</Link></li>
                        <li><Link to="/roadmap">Roadmap</Link></li>
                        <li><Link to="/memes">Memes</Link></li>
                    </ul>
                    <div className="social-icons">
                        <a href="http://t.me/FoxyKaspa" target="_blank" rel="noopener noreferrer" aria-label="tg">
                            <img className='tg' src="/images/logoTg.png" alt="tg" />
                        </a>
                        <a href="https://x.com/FoxyKaspa" target="_blank" rel="noopener noreferrer" aria-label="x">
                            <img className='x' src="/images/logoX.png" alt="x" />
                        </a>
                    </div>
                    </div>
                </nav>
            </header>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/tokenomics" element={<Tokenomics />} />
                <Route path="/roadmap" element={<Roadmap />} />
                <Route path="/memes" element={<Memes />} />
            </Routes>
        </div>
    );
}

function Home() {
    return (
        <div className='main-title'>
        <section className="hero-section">
            <h1 className="title">Foxy</h1>
        </section>
        </div>
    );
}

export default App;
