import React from 'react';

function Roadmap() {
    return (
        <div className='roadmap-box'>
            <h1 className='roadmap-title'>Roadmap</h1>
			<div className='roadmap-info'>
			<p1 className='p1'>
			<span className='chapter'>
				Chapter 1
				</span>
				<br></br>
				- Community
				<br></br>
				- Chainge LP
				<br></br>
				- Memes + Arts
				</p1>
			<p2 className='p2'>
			<span className='chapter'>

			Chapter 2
			</span>
			<br></br>
			- Royalty = Burn Supply
				<br></br>
				- Collaborations

				<br></br>
				- Benefits for Whales
			</p2>
			<p3 className='p3'>
			<span className='chapter'>
				Chapter 3
				</span>
			<br></br>
			- DAO
				<br></br>
				- NFT
				<br></br>
				- Merch & Brand 
			</p3>
			</div>
           
        </div>
    );
}

export default Roadmap;
