import React from 'react';


function Tokenomics() {
    return (
		<div className='tokenomics-box'>
        <div className='tokenomics'>
            <h1 className='tokenomics-tittle'>Tokenomics</h1>
            <p className='tokenomics-info'>
				<div className='tokenomics-infobox'>
				<span className='tokenomics-p'>	
				• Supply - 1.000.000.000 FOXY
				</span>
				</div>
				<div className='tokenomics-infobox'>
				<span className='tokenomics-p'>
				• 50% Token Sale
					</span> 
				</div>
			   <div className='tokenomics-infobox'>
			   <span className='tokenomics-p'>	
			   • 50% LP
				</span>
			   </div>
			   <div className='tokenomics-infobox'>
			   <span className='tokenomics-p'>• 1 $KAS - 1666 $FOXY</span>
			   </div>
				<div className='tokenomics-infobox'>
				<span className='tokenomics-p'>• 1 $FOXY - 0.0006 $KAS</span> 
				</div>	
				<div className='tokenomics-infobox'>
				<span className='tokenomics-p'>• Initial Mcap: 600.000 $KAS</span> 
				</div>		   
			   </p>
        </div>
		</div>
    );
}

export default Tokenomics;
